<script setup lang="ts">
import { ref } from "vue";
import type { Body, Field } from "./job-list.vue";

const props = defineProps<{
  raw: Body;
  fields: Field;
}>();

const raw = ref(props.raw);

defineEmits<{
  (e: "changeRaw"): void;
}>();

function resetFilters() {
  raw.value.filter = {
    "custom.bereich": {
      in: [],
    },
    "custom.oda": {
      in: [],
    },
    "custom.zeitmodell": {
      in: [],
    },
  };
}
</script>

<template>
  <div class="joblist-filter">
    <div>
      <h3 class="joblist-filter-headline">Filter</h3>
      <div class="joblist-filter-items">
        <select
          class="joblist-filter-multiselect"
          @input="$emit('changeRaw')"
          v-model="raw.filter['custom.bereich'].in"
          multiple
        >
          <option
            v-for="job in fields['custom.bereich']?.options"
            :key="job.value"
            :value="job.value"
          >
            {{ job.label }}
          </option>
        </select>
        <select
          class="joblist-filter-multiselect"
          @input="$emit('changeRaw')"
          v-model="raw.filter['custom.oda'].in"
          multiple
        >
          <option
            v-for="oda in fields['custom.oda']?.options"
            :key="oda.value"
            :value="oda.value"
          >
            {{ oda.label }}
          </option>
        </select>
        <select
          class="joblist-filter-multiselect"
          @input="$emit('changeRaw')"
          v-model="raw.filter['custom.zeitmodell'].in"
          multiple
        >
          <option
            v-for="zeitmodell in fields['custom.zeitmodell']?.options"
            :key="zeitmodell.value"
            :value="zeitmodell.value"
          >
            {{ zeitmodell.label }}
          </option>
        </select>
      </div>
      <a class="joblist-filter-reset" @click="resetFilters">
        Filter zurücksetzten
      </a>
    </div>
    <div>
      <h3 class="joblist-filter-headline">Sortierung</h3>
      <div class="joblist-filter-items">
        <select
          class="joblist-filter-item joblist-filter-dropdown btn btn-secondary dropdown-toggle"
          v-model="raw.sort.by"
        >
          <option value="title">Titel</option>
          <option value="createdOn">Erstellungsdatum</option>
        </select>
        <select
          class="joblist-filter-item joblist-filter-dropdown btn btn-secondary dropdown-toggle"
          @input="$emit('changeRaw')"
          v-model="raw.sort.order"
        >
          <option value="asc">Aufsteigend</option>
          <option value="desc">Absteigend</option>
        </select>
      </div>
    </div>
  </div>
</template>

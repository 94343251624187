import { createApp } from "vue";
import App from "./App.vue";

export interface Config {
  [key: string]: string;
}

declare const mercury: any;

declare global {
  interface Window {
    biteLoaded: boolean;
  }
}

if (!window.biteLoaded) {
  window.biteLoaded = true;

  document.addEventListener("DOMContentLoaded", (event) => {
    if (!document.getElementById("joblist")) {
      console.error("BITE mount element not found");

      mercury.ready(() => {
        init();
      });
    } else {
      init();
    }
  });
}

function init() {
  const mountEl = document.getElementById("joblist") as HTMLElement;
  const joblist = createApp(App, { ...mountEl?.dataset });
  joblist.config.globalProperties = Object.assign(
    joblist.config.globalProperties,
    {
      ...mountEl?.dataset,
    }
  );
  const config = joblist.config.globalProperties as Config;
  joblist.provide<Config>("config", config);

  joblist.mount("#joblist");
}

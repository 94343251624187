<script setup lang="ts">
import jobList from "./components/job-list.vue";
import { inject } from "vue";
import type { Config } from "./main";

const config = inject<Config>("config");
</script>

<template>
  <main>
    <ul class="joblist-debuginfo">
      <li>Area: {{ config?.area }}</li>
      <li>Pagination: {{ config?.pagination }}</li>
    </ul>
    <job-list />
  </main>
</template>

<script lang="ts" setup>
import type { Config } from "@/main";
import useSWRV from "swrv";
import { computed, inject, ref, watch } from "vue";
import jobListItem from "./job-list-item.vue";
import jobFilter from "./job-filter.vue";

export interface Job {
  id: string;
  active: boolean;
  anr: number;
  assignees: number[];
  channels: Channels;
  emailTemplate: string;
  hash: string;
  keywords: string[];
  locale: string;
  location: Location;
  seo: SEO;
  subsidiary: string;
  title: string;
  custom: Custom;
  // content: Content;
  createdOn: string;
  modifiedOn: string;
  activatedOn: string;
  url?: string;
  applyUrl?: string;
}

export interface Jobs {
  jobPostings?: Array<Job>;
  page: Page;
  fields: Field;
}

export interface Field {
  title: string;
  "adress.city": string;
  "adress.postcode": string;
  "custom.bereich"?: CustomField;
  "custom.oda"?: CustomField;
  "custom.zeitmodell"?: CustomField;
  description: string;
  identification: string;
  jobSite: string;
  startsOn: string;
  endsOn: string;
  createdOn: string;
  modifiedOn: string;
  geoDistance: string;
  custom: Custom;
}
export interface CustomField {
  type: string;
  label: string;
  options: [
    {
      value: string;
      label: string;
    }
  ];
}

export interface Page {
  offset: number;
  total: number;
}

export interface Channels {
  [key: string]: Channel;
}

export interface Channel {
  from: string;
  to: string;
  route: Route;
}

export interface Route {
  email: string;
  posting: string;
  application: string;
  emailApplication: string;
  html: string;
  htmlSimple: string;
  text: string;
  markdown: string;
  pdf: string;
}

export interface Content {
  css: string;
  html: string;
}

export interface Custom {
  custom_field1?: string;
  custom_field2?: string;
  custom_field3?: string;
  bereich?: string[];
  oda?: string;
  zeitmodell: string;
}

export interface CustomFieldOption {
  custom_field1: string;
  custom_field2: string;
  custom_field3: string;
  bereich: string[];
}

export interface Location {
  city: string;
  country: null;
  houseNumber: string;
  latitude: number;
  longitude: number;
  postCode: string;
  street: string;
}

export interface SEO {
  employmentType: string[];
  openGraph: null;
  salary: null;
}

export interface Body {
  key: string;
  channel: number;
  locale: "de" | "en";
  origin: string;
  page: PageOptions;
  sort: SortOptions;
  filter: FilterOptions;
}

export interface SortOptions {
  order: "asc" | "desc";
  by: "title" | "createdOn";
}
export interface FilterOptions {
  "custom.bereich": {
    in: Array<string>;
  };
  "custom.oda": {
    in: Array<string>;
  };
  "custom.zeitmodell": {
    in: Array<string>;
  };
}
export interface PageOptions {
  num: number;
  offset: number;
}

const config = inject<Config>("config");
const pagination = ref(
  parseInt(
    config?.pagination
      ? config.pagination.length === 0
        ? "1000"
        : config.pagination
      : "1000"
  )
);
const area = ref(config?.area ?? "");
const hasFilter = ref(config?.filter == "true");

const headers = new Headers();
headers.append("Content-Type", "application/json");

const raw = ref<Body>({
  key: import.meta.env.VITE_API_KEY,
  channel: 0,
  locale: "de",
  origin: import.meta.env.VITE_ORIGIN,
  page: {
    num: pagination.value,
    offset: 0,
  },
  sort: {
    order: "desc",
    by: "createdOn",
  },
  filter: {
    "custom.bereich": {
      in: area.value.replace(/\s/g, "").split(","),
    },
    "custom.oda": {
      in: [""],
    },
    "custom.zeitmodell": {
      in: [""],
    },
  },
});

const requestOptions = computed(() => ({
  method: "POST",
  headers: headers,
  body: JSON.stringify(raw.value),
}));

const {
  data: jobs,
  error,
  mutate,
} = useSWRV<Jobs>(
  () => `https://jobs.b-ite.com/api/v1/postings/search`,
  () =>
    fetch(
      import.meta.env.VITE_ENDPOINT + import.meta.env.VITE_JOBS_ENDPOINT,

      requestOptions.value
    ).then((res) => {
      return res.json();
    })
);

watch(jobs, () => {
  if (!jobs.value) return;
  const chooseAllField: {
    value: string;
    label: string;
  } = {
    value: "",
    label: "Alles",
  };

  jobs.value.fields["custom.bereich"]?.options.unshift(chooseAllField);
  jobs.value.fields["custom.oda"]?.options.unshift(chooseAllField);
  jobs.value.fields["custom.zeitmodell"]?.options.unshift(chooseAllField);
});

watch(
  raw,
  async () => {
    await mutate(() =>
      fetch(
        import.meta.env.VITE_ENDPOINT + import.meta.env.VITE_JOBS_ENDPOINT,

        requestOptions.value
      ).then((res) => res.json())
    );
  },
  { deep: true }
);

const pageNumbers = computed(() => {
  if (jobs.value) return Math.ceil(jobs.value?.page.total / pagination.value);
  return 1;
});

function increaseOffset() {
  if (!config?.pagination || !jobs.value?.page.total) return;
  if (raw.value.page.offset + pagination.value >= jobs.value?.page.total) {
    return;
  } else {
    raw.value.page.offset += pagination.value;
  }
}
function decreaseOffset() {
  if (!config?.pagination || !jobs.value?.page.total) return;
  if (raw.value.page.offset - pagination.value < 0) {
    return;
  } else {
    raw.value.page.offset -= pagination.value;
  }
}
function resetOffset() {
  raw.value.page.offset = 0;
}
function resetFilters() {
  raw.value.filter = {
    "custom.bereich": {
      in: [],
    },
    "custom.oda": {
      in: [],
    },
    "custom.zeitmodell": {
      in: [],
    },
  };
}
</script>
<template>
  <section class="joblist-wrapper">
    <template v-if="error">
      <p>Error: {{ error }}</p>
    </template>
    <template v-else-if="!jobs">
      <div>
        <div class="skeleton skeleton-text"></div>
        <div class="skeleton skeleton-text"></div>
        <div class="skeleton skeleton-text"></div>
      </div>
    </template>
    <template v-else-if="!jobs.jobPostings">
      <job-filter
        v-if="hasFilter"
        :raw="raw"
        :fields="jobs.fields"
        @changeRaw="resetOffset"
      />

      <p class="joblist-novalue">
        Aktuell sind leider keine Stellenangebote in diesem Bereich verfügbar.
      </p>
      <button v-if="hasFilter" class="btn piece-btn" @click="resetFilters">
        Filter zurücksetzten
      </button>
    </template>
    <template v-else>
      <job-filter
        v-if="hasFilter"
        :raw="raw"
        :fields="jobs.fields"
        @changeRaw="resetOffset"
      />

      <job-list-item
        v-for="job in jobs.jobPostings"
        :key="job.id"
        :job="job"
        :fields="jobs.fields"
      />

      <div v-if="pageNumbers > 1" class="joblist-pagination">
        <ul class="pagination">
          <li class="previous">
            <a @click="decreaseOffset">
              <span class="sr-only">Vorherige Seite</span>
              <span class="ico fa fa-angle-left" aria-hidden="true"></span>
            </a>
          </li>
          <li
            class="page"
            :class="{ active: raw.page.offset === i * pagination - pagination }"
            v-for="i in pageNumbers"
            :key="i"
          >
            <a @click="raw.page.offset = i * pagination - pagination">
              <span class="number" aria-hidden="true"> {{ i }} </span>
            </a>
          </li>
          <li class="next">
            <a @click="increaseOffset">
              <span class="sr-only">Nächste Seite</span>
              <span class="ico fa fa-angle-right" aria-hidden="true"></span>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </section>
</template>
